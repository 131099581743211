<script>
import { api } from "@/state/services";

export default {
  components: {
  },
  data() {
    return {
      rss: {
        list: []
      }
    }
  },
  methods: {
    getRss() {
      api
        .get('rss?limit=5')
        .then(response => {
          if (response.data.status == 'success') {
            this.rss.list = response.data.list
          }
        });
    },
  },
  mounted() {
    this.getRss()
  },
}
</script>

<template>
  <div>
    <div v-if="this.rss.list.length > 0" class="mb-5 px-3 pt-0">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-12" v-for="(row,index) in this.rss.list" :key="index">
          <div class="post card mb-2">
            <a target="_blank" rel="noreferrer" :href="row.url" class="text-dark">
              <div class="card-body p-0 d-flex">
                <div class="post-cover align-self-center">
                  <div class="post-image" :style="'background-image: url(' + row.cover + ');'"></div>
                </div>
                <div class="p-3 align-self-center">
                  <h5 class="post-title font-size-15 mb-2">{{ row.title }}</h5>
                  <p class="post-date font-size-12 text-muted m-0">{{ row.date }}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center p-4">
      <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
    </div>
  </div>
</template>

<style scoped>
.post-cover {
  height: 120px;
}
.post-image {
  display: inline-block;
  background-position: center;
  background-size: cover;
  border-radius: 3px;
  width: 120px;
  height: 120px;
}
.post-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media (max-width: 500px) {
  .post {
    margin-left: -15px;
    margin-right: -15px;
  }
}
</style>