<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartInvestments from "@/components/widgets/chart-investments";
import TradingViewTv from "@/components/widgets/chart-tradingview-tv";
import TradingViewTicker from '@/components/widgets/chart-tradingview-ticker.vue';
import Rss from '@/components/widgets/rss.vue';

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartInvestments,
    TradingViewTicker,
    TradingViewTv,
    Rss,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        contracts: "***,** BRL",
        profits: "***,** BRL",
        withdrawals: "R -,--",
        balance: "***,** BRL",
      },

      statData: null,

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,

      profits: {
        loading: true,
        year: '2024',
        list: [],
        accumulated: '0%',
        average: '0%',
      },

      ibovespa: {
        list: [],
        accumulated: '0%',
        average: '0%',
      },
    };
  },
  methods: {
    getTotalInvestiments() {
      api
        .get('investments/contracts/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.contracts = this.$options.filters.currency(response.data.total.contracts)
            this.total.profits   = this.$options.filters.currency(response.data.total.profits)
            this.getStatData();
          }
        })
    },
    getTotalWithdrawals() {
      api
        .get('wallet/withdrawal/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.withdrawals = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Saldo Disponível",
          value: this.total.balance
        },
        {
          title: "Total Aportado",
          value: this.total.contracts,
        },
        {
          title: "Rendimentos Totais",
          value: this.total.profits,
        },
      ]
    },
    getProfits() {
      this.profits.loading = true
      this.profits.list = []
      this.profits.accumulated = 0
      this.profits.average = 0

      api
        .get('investments/profits?year=' + this.profits.year)
        .then((response) => {
          if (response.data.status == 'success') {
            this.profits.list = response.data.contract.list
            this.profits.accumulated = response.data.contract.accumulated
            this.profits.average = response.data.contract.average

            this.ibovespa.list = response.data.ibovespa.list
            this.ibovespa.accumulated = response.data.ibovespa.accumulated
            this.ibovespa.average = response.data.ibovespa.average
          }

          this.profits.loading = false
        })
    },
    changeProfit() {
      this.getProfits()
    }
  },
  mounted() {
    this.getTotalInvestiments()
    this.getTotalWithdrawals()
    this.getTotalBalance()
    this.getStatData()
    this.getProfits()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h5>Bem-vindo à SG Gestão de Capital</h5>
        <p>Confira um resumo da sua conta.</p>
      </div>
    </div>

    <div class="card" v-if="account.user.package.sponsored == 'yes'">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
            " v-on:click="$toast.success('Copiado!');">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-none justify-content-center mb-3">
          <div class="px-2">
            <input class="form-control" v-model="profits.year" type="number" min="2024" step="1" />
          </div>
          <div>
            <b-button type="submit" variant="default" v-on:click="changeProfit()">
              {{ t('Filtrar') }}
            </b-button>
          </div>
        </div>

        <hr class="d-none mt-1">

        <ChartInvestments :year="profits.year" />

        <div v-if="profits.loading" class="text-center">
          <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
        </div>
        <div v-else-if="Object.keys(profits.list).length > 0" class="table-responsive mt-3">
          <table class="table-profit table table-sm table-bordered text-center">
            <thead>
              <tr class="table-year">
                <th v-for="(months, year) in profits.list" :key="year" colspan="100%">{{ year }}</th>
              </tr>
              <tr class="table-month">
                <th></th>
                <template v-for="(months) in profits.list">
                  <th v-for="(mon) in months" :key="mon.id">{{ mon.month }}</th>
                </template>
                <th>Acumulado</th>
                <th>Média</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="bg-soft-success align-middle">SG GESTÃO CAPITAL</td>
                <template v-for="(months) in profits.list">
                  <td class="bg-soft-success" v-for="(mon) in months" :key="mon.id">
                    {{ mon.percent }}
                  </td>
                </template>
                <td class="bg-soft-success align-middle">
                  {{ profits.accumulated }}
                </td>
                <td class="bg-soft-success align-middle">{{ profits.average }}</td>
              </tr>
              <tr>
                <td class="bg-soft-danger align-middle">IBOVESPA</td>
                <template v-for="(months) in ibovespa.list">
                  <td class="bg-soft-danger" v-for="(mon) in months" :key="mon.id">
                    {{ mon.percent }}
                  </td>
                </template>
                <td class="bg-soft-danger align-middle">
                  {{ ibovespa.accumulated }}
                </td>
                <td class="bg-soft-danger align-middle">{{ ibovespa.average }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center">
          Nenhuma informação disponível neste período.
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body p-4">
            <TradingViewTicker class="mb-3"></TradingViewTicker>
            <TradingViewTv></TradingViewTv>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 p-0">
        <Rss></Rss>
      </div>
    </div>
  </Layout>
</template>